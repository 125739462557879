var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('VDataTable',{directives:[{name:"show",rawName:"v-show",value:(_vm.prolongationRows.length > 0),expression:"prolongationRows.length > 0"}],staticClass:"expandable-table data-table-inner rounded-off space-b-6",attrs:{"headers":_vm.prolongationHeaders,"mobile-breakpoint":0,"items":_vm.prolongationRows,"expanded":_vm.prolongationExpanded,"single-expand":false,"item-key":"group","show-expand":"","hide-default-header":"","hide-default-footer":""},on:{"update:expanded":function($event){_vm.prolongationExpanded=$event}},scopedSlots:_vm._u([{key:"item.count",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.count)+" шт")]}},{key:"item.group",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.group)+" мес")]}},{key:"item.averagePrice",fn:function(ref){
var item = ref.item;
return [_c('span',{directives:[{name:"number",rawName:"v-number",value:(item.averagePrice),expression:"item.averagePrice"}]})]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [(!_vm.readonly)?_c('VBtn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.handleDeleteProlongationGroup(+item.group)}}},[_c('VIcon',[_vm._v("$delete")])],1):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('table',{staticClass:"expanded-table"},_vm._l((item.packages),function(pack,index){return _c('tr',{key:pack.id || ("package_" + index)},[_c('td',{staticClass:"expanded-table__cell",attrs:{"width":"5%"}}),_c('td',{staticClass:"expanded-table__cell",attrs:{"width":"55%","colspan":"2"}},[_vm._v(_vm._s(_vm.getMonthsRangeByIds(pack.monthIds, _vm.group.months)))]),_c('td',{directives:[{name:"number",rawName:"v-number",value:(pack.sum),expression:"pack.sum"}],staticClass:"expanded-table__cell",attrs:{"width":"25%"}}),_c('td',{staticClass:"expanded-table__cell",attrs:{"width":"5%"}},[(!_vm.readonly)?_c('VBtn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.handleDeleteProlongation(pack.monthIds)}}},[_c('VIcon',[_vm._v("$delete")])],1):_vm._e()],1)])}),0)])]}}])}),(!_vm.readonly)?[_c('ButtonTextIcon',{attrs:{"color":"secondary"},on:{"click":function($event){_vm.showProlongationModal = true}}},[_vm._v("Добавить")]),_c('AddProlongationPackagesModal',{attrs:{"group":_vm.group,"visible":_vm.showProlongationModal},on:{"update:visible":function($event){_vm.showProlongationModal=$event},"add":_vm.handleAddProlongation,"close":function($event){_vm.showProlongationModal = false}}})]:_vm._e()],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header-wrapper space-b-6"},[_c('div',{staticClass:"text-body-3"},[_vm._v("Пакеты для продления")]),_c('div',{staticClass:"text-body-1 grey--text"},[_vm._v("Стоимость пакета, ₽")])])}]

export { render, staticRenderFns }