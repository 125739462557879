



















































import { groupBy, isEqual, reduce, sumBy, unionWith } from 'lodash'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'

import AddProlongationPackagesModal from '@/components/modals/packages/AddProlongationPackagesModal.vue'
import CourseGroupMonthMixin from '@/mixins/manager/CourseGroupMonthMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import { GroupLargeResource, GroupPackagesData, GroupStoreMonthsData, PackageStoreData } from '@/store/types'

@Component({
  components: {
    AddProlongationPackagesModal,
    ValidationProvider,
  },
})
export default class GroupPackagesProlongationForm extends Mixins(CourseGroupMonthMixin, NotifyMixin) {
  @Prop({ required: true })
  private group!: GroupLargeResource

  @Prop({ required: true })
  private form!: GroupPackagesData

  @Prop({ default: false })
  private readonly!: boolean

  private showProlongationModal = false
  private prolongationExpanded: number[] = []

  private get prolongationHeaders () {
    return [
      { cellClass: 'cell-width-5', text: '', value: 'data-table-expand' },
      { cellClass: 'cell-width-40', text: '', value: 'group' },
      { cellClass: 'cell-width-15', text: '', value: 'count' },
      { cellClass: 'cell-width-25', text: '', value: 'averagePrice' },
      { cellClass: 'cell-width-5', text: '', value: 'delete' },
    ]
  }

  private get prolongationRows () {
    const groups = groupBy(this.form.prolongation, 'monthIds.length')
    const rows = []

    for (const count in groups) {
      const packages = groups[count].map((month: PackageStoreData) => ({
        ...month,
        sum: reduce(month.monthIds, (sum: number, id: number) => {
          const formMonth = this.form.months.find((item: GroupStoreMonthsData) => item.id === id)
          return sum + (formMonth ? +formMonth.price : 0)
        }, 0),
      }))
      const averagePrice = Math.round(sumBy(packages, 'sum') / packages.length)

      rows.push({
        averagePrice: `${averagePrice === packages[0].sum ? '' : '~'}${averagePrice}`,
        count: groups[count].length,
        group: count,
        packages,
      })
    }

    return rows
  }

  private handleAddProlongation (packages: PackageStoreData[]) {
    // Если добавляется пакет со всеми месяцами или с месяцами на выбор, то проверить их на наличие и выдать ошибку
    // иначе добавить
    if (packages.length === 1) {
      const index = this.form.prolongation.findIndex((item: PackageStoreData) => isEqual(item.monthIds, packages[0].monthIds))
      if (index !== -1) {
        this.notifyError('Такой пакет уже существует.')
      } else {
        this.form.prolongation = [
          ...this.form.prolongation,
          ...packages,
        ]
        this.$bus.$emit('resetPackagesAddForm')
      }
    } else {
      this.form.prolongation = [...unionWith(
        this.form.prolongation,
        packages,
        (pack1: PackageStoreData, pack2: PackageStoreData) => isEqual(pack1.monthIds, pack2.monthIds)),
      ]
      this.$bus.$emit('resetPackagesAddForm')
    }
  }

  private handleDeleteProlongation (monthIds: number[]) {
    const index = this.form.prolongation.findIndex((item: PackageStoreData) => isEqual(item.monthIds, monthIds))
    if (index >= 0) {
      this.form.prolongation.splice(index, 1)
    }
  }

  private handleDeleteProlongationGroup (monthsCount: number) {
    this.form.prolongation = this.form.prolongation.filter((item: PackageStoreData) => item.monthIds && item.monthIds.length !== monthsCount)
  }
}
