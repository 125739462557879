























































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'

import Select from '@/components/_uikit/controls/Select.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import { GroupLargeResource, GroupPackagesData, MonthShortResource } from '@/store/types'
import { formatDate } from '@/utils/functions'

@Component({
  components: {
    Select,
    TextInput,
    ValidationProvider,
  },
})
export default class GroupPackagesStarterForm extends Mixins(NotifyMixin) {
  @Prop({ required: true })
  private group!: GroupLargeResource

  @Prop({ required: true })
  private form!: GroupPackagesData

  @Prop({ default: false })
  private readonly!: boolean

  @Prop({ default: null })
  private starterIDDefaultSelect!: number | null

  private get monthsList () {
    return this.group.months.map((month: MonthShortResource) => ({
      name: formatDate(month.name, 'LLLL'),
      value: month.id,
    }))
  }

  private handleAddStarter () {
    this.form.starter.push({
      isDefault: false,
      monthIds: [],
      price: undefined,
    })
  }

  private handleChangeStarterMonths (index: number) {
    const starter = this.form.starter[index]
    let totalPrice = 0
    starter.monthIds.map(monthID => {
      totalPrice += Number(this.form.months.find(month => month.id === monthID)?.price || 0)
    })
    if (starter && starter.monthIds) {
      starter.price = totalPrice
    }
  }

  private handleDeleteStarter (index: number) {
    this.form.starter.splice(index, 1)
  }
}
