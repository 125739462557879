



































import { reduce, unionBy } from 'lodash'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'

import SearchPackageModal from '@/components/modals/packages/SearchPackageModal.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import Tag from '@/components/_uikit/Tag.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import CourseGroupMonthMixin from '@/mixins/manager/CourseGroupMonthMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import {
  CourseLargeResource,
  GroupPackagesData,
  GroupPackagesResource,
  NameValueResource,
  PackageShortResource,
} from '@/store/types'

@Component({
  components: {
    SearchPackageModal,
    Select,
    Tag,
    TextInput,
    ValidationProvider,
  },
})
export default class GroupPackagesTransferForm extends Mixins(CourseGroupMonthMixin, NotifyMixin) {
  @Prop({ default: null })
  private course!: CourseLargeResource

  @Prop({ required: true })
  private packages!: GroupPackagesResource

  @Prop({ required: true })
  private form!: GroupPackagesData

  @Prop({ default: false })
  private readonly!: boolean

  private showSearchPackageModal = false

  private mounted () {
    this.innerPackages = reduce(this.packages.transfer, (months: PackageShortResource[], item: PackageShortResource) => {
      if (item.package) {
        months.push(item.package)
      }
      return months
    }, [])
  }

  private handleAddTransfer (packages: PackageShortResource[]) {
    this.innerPackages = unionBy(this.innerPackages, packages, 'id')
    this.form.transfer.push(...packages.map((item: PackageShortResource) => ({
      monthIds: item.months.map((month: NameValueResource) => +month.value),
      packageId: item.id,
      price: item.price,
    })))
  }

  private handleDeleteTransfer (index: number) {
    this.form.transfer.splice(index, 1)
  }
}
