



































































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import CardHint from '@/components/_uikit/CardHint.vue'
import SwitchInput from '@/components/_uikit/controls/SwitchInput.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import CourseGroupMonthMixin from '@/mixins/manager/CourseGroupMonthMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import {
  CourseLargeResource,
  CourseType,
  GroupLargeResource,
  GroupPackagesData,
  GroupStoreMonthsData, GroupType,
} from '@/store/types'
import { getHalfsOfList } from '@/utils/functions'
import DictionaryModule from '@/store/modules/dictionary'
import Select from '@/components/_uikit/controls/Select.vue'
@Component({

  components: {
    CardHint,
    Select,
    SwitchInput,
    TextInput,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class GroupPackagesPriceForm extends Mixins(CourseGroupMonthMixin, NotifyMixin) {
  @Prop({ required: true })
  private course!: CourseLargeResource

  @Prop({ required: true })
  private group!: GroupLargeResource

  @Prop({ required: true })
  private form!: GroupPackagesData

  private getHalfsOfList = getHalfsOfList

  private get cashboxes () {
    return DictionaryModule.cashboxes
  }

  private get isSpecial () {
    return this.course.type.value === CourseType.SPECIAL
  }

  private get isGroupLite () {
    return this.group.type.value === GroupType.LITE
  }

  private handleChangePricePerMonth (value: string) {
    // Если не спецкурс, то меняем ценник во всех месяцах, иначе только в первом
    if (!this.isSpecial) {
      this.form.months = this.form.months.map((month: GroupStoreMonthsData) => ({
        ...month,
        price: +value,
      }))
    } else {
      this.form.months = this.form.months.map((month: GroupStoreMonthsData, idx: number) => {
        if (idx === 0) {
          return {
            ...month,
            price: value === '' ? this.form.fakePrice : +value,
          }
        } else {
          return {
            ...month,
            price: 0,
          }
        }
      })
    }
  }
}
