var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CardWrapper',{staticClass:"space-b-6",scopedSlots:_vm._u([(!_vm.isSpecial)?{key:"hintTop",fn:function(){return [_c('CardHint',{attrs:{"text":"При изменении стоимости месяца, пересчитывается и меняется цена пакетов для продления"}})]},proxy:true}:null],null,true)},[_c('VCardText',[_c('div',{staticClass:"card-grid month-price"},[(_vm.isSpecial)?_c('ValidationProvider',{staticClass:"month-price__field",attrs:{"name":"fakePrice","rules":"required|min_value:0","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"name":"fakePrice","label":"Стоимость курса, ₽","type":"number","placeholder":"Введите стоимость","invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.fakePrice),callback:function ($$v) {_vm.$set(_vm.form, "fakePrice", $$v)},expression:"form.fakePrice"}})]}}],null,false,3349682782)}):_vm._e(),_c('ValidationProvider',{staticClass:"month-price__field",attrs:{"name":"pricePerMonth","rules":{ required: !_vm.isSpecial, min_value: 0, max_value: _vm.isSpecial ? _vm.form.fakePrice : 100000000 },"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"name":"pricePerMonth","label":_vm.isSpecial ? 'Цена со скидкой, ₽' : 'Стоимость месяца, ₽',"type":"number","disabled":!_vm.isSpecial && (_vm.form.closedForSales || _vm.form.customMonthPrice),"placeholder":"Введите стоимость","invalid":errors.length > 0,"error":errors[0]},on:{"input":_vm.handleChangePricePerMonth},model:{value:(_vm.form.pricePerMonth),callback:function ($$v) {_vm.$set(_vm.form, "pricePerMonth", $$v)},expression:"form.pricePerMonth"}})]}}])}),(!_vm.isSpecial)?_c('div',{staticClass:"month-price__toggler"},[(!_vm.form.customMonthPrice)?_c('ButtonIconAction',{attrs:{"icon":"$edit"},on:{"click":function($event){_vm.form.customMonthPrice = true}}}):_c('LinkPrimary',{attrs:{"tag":"button","text":"","color":"primary"},on:{"click":function($event){_vm.form.customMonthPrice = false}}},[_vm._v("Скрыть месяцы")])],1):_vm._e(),_c('div',{staticClass:"month-price__close"},[(!_vm.isGroupLite)?_c('SwitchInput',{attrs:{"label":"Закрыто для продаж"},model:{value:(_vm.form.closedForSales),callback:function ($$v) {_vm.$set(_vm.form, "closedForSales", $$v)},expression:"form.closedForSales"}}):_vm._e()],1)],1),_c('VDivider',{staticClass:"my-6"}),_c('div',{staticClass:"card-grid"},[_c('div',{staticClass:"text-body-3 cart-title"},[_vm._v("Касса для оплаты")]),_c('ValidationProvider',{staticClass:"cart-select",attrs:{"name":"cashboxId","rules":"requiredSelect","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Select',{attrs:{"options":_vm.cashboxes,"placeholder":"Выберите кассу","dense":"","rounded":"","outlined":"","invalid":errors.length > 0,"error":errors[0]},model:{value:(_vm.form.cashboxId),callback:function ($$v) {_vm.$set(_vm.form, "cashboxId", $$v)},expression:"form.cashboxId"}})]}}])})],1)],1),(!_vm.isSpecial && _vm.form.customMonthPrice)?[_c('VDivider',{staticClass:"my-0"}),_c('VCardText',[_c('div',{staticClass:"text-body-1 grey--text mb-6"},[_vm._v("Выбор месяцев для показа на графике при покупке")]),_c('div',{staticClass:"months-container"},[_c('div',{staticClass:"months-container__col"},_vm._l((_vm.getHalfsOfList(_vm.form.months).head),function(month){return _c('div',{key:month.id,staticClass:"months-container__item"},[_c('span',{staticClass:"months-container__label"},[_vm._v(_vm._s(_vm.getMonthByID(month.id, _vm.group.months)))]),_c('ValidationProvider',{staticClass:"months-container__input",attrs:{"name":("monthPrice-" + (month.id)),"rules":"required|min_value:0","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"name":("monthPrice-" + (month.id)),"suffix":"₽","type":"number","dense":"","outlined":"","disabled":_vm.form.closedForSales,"placeholder":"Введите стоимость","invalid":errors.length > 0,"error":errors[0]},model:{value:(month.price),callback:function ($$v) {_vm.$set(month, "price", $$v)},expression:"month.price"}})]}}],null,true)}),_c('VCheckbox',{attrs:{"ripple":false},model:{value:(month.isVisibleInShop),callback:function ($$v) {_vm.$set(month, "isVisibleInShop", $$v)},expression:"month.isVisibleInShop"}})],1)}),0),_c('div',{staticClass:"months-container__col"},_vm._l((_vm.getHalfsOfList(_vm.form.months).tail),function(month){return _c('div',{key:month.id,staticClass:"months-container__item"},[_c('span',{staticClass:"months-container__label"},[_vm._v(_vm._s(_vm.getMonthByID(month.id, _vm.group.months)))]),_c('ValidationProvider',{staticClass:"months-container__input",attrs:{"name":("monthPrice-" + (month.id)),"rules":"required|min_value:0","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TextInput',{attrs:{"name":("monthPrice-" + (month.id)),"suffix":"₽","type":"number","dense":"","outlined":"","disabled":_vm.form.closedForSales,"placeholder":"Введите стоимость","invalid":errors.length > 0,"error":errors[0]},model:{value:(month.price),callback:function ($$v) {_vm.$set(month, "price", $$v)},expression:"month.price"}})]}}],null,true)}),_c('VCheckbox',{attrs:{"ripple":false},model:{value:(month.isVisibleInShop),callback:function ($$v) {_vm.$set(month, "isVisibleInShop", $$v)},expression:"month.isVisibleInShop"}})],1)}),0)])])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }