























































































import { Bind, Debounce } from 'lodash-decorators'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

import CourseSearch from '@/components/_uikit/search/CourseSearch.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import TableFooter from '@/components/_uikit/TableFooter.vue'
import Tag from '@/components/_uikit/Tag.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import CourseGroupMonthMixin from '@/mixins/manager/CourseGroupMonthMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import { GET_DEFAULT_PAGINATOR, GET_DEFAULT_TABLE_FILTER } from '@/store'
import DictionaryModule from '@/store/modules/dictionary'
import ManagerCoursesModule from '@/store/modules/manager/courses'
import {
  ISearchPackagesFilter,
  ISearchPackagesFilterType,
  PackageShortResource,
  PackageShortResourcePaginator,
  PackageType,
} from '@/store/types'
import { tableFooterOptions } from '@/utils/constants'

@Component({
  components: {
    CourseSearch,
    Select,
    TableFooter,
    Tag,
    TextInput,
  },
})
export default class SearchPackageModal extends Mixins(CourseGroupMonthMixin, NotifyMixin) {
  @Prop({ default: null })
  private excludeCourseId!: number

  @Prop({ required: true })
  private visible!: boolean

  @Prop({ default: false })
  private prolongation!: boolean

  @Prop({ required: true })
  private selected!: PackageShortResource[]

  private get packageTypesDictionary() {
    return DictionaryModule.packageTypes
  }

  private get packageTypes() {
    const types: Record<string, string> = {}
    this.packageTypesDictionary.forEach(pack => types[pack.value] = pack.name)
    return types
  }

  private scrollOptions = {
    scrollPanel: {
      scrollingX: false,
    },
  }

  private filter: ISearchPackagesFilter = Object.assign(
    {},
    { ...GET_DEFAULT_TABLE_FILTER(), itemsPerPage: 10 },
  )
  private footerOptions = tableFooterOptions

  private packages: PackageShortResourcePaginator = GET_DEFAULT_PAGINATOR()
  private innerSelected: PackageShortResource[] = []

  private get headers () {
    return [
      { cellClass: 'cell-icon cell-icon_first', text: '', value: 'data-table-select' },
      { sortable: false, text: 'Название курса', value: 'title' },
      { sortable: false, text: 'Тип группы', value: 'type' },
      { sortable: false, text: 'Тип пакета', value: 'typePackage' },
      { sortable: false, text: 'Пакет', value: 'package' },
      { sortable: false, text: 'Стоимость, ₽', value: 'price' },
    ]
  }

  private get types () {
    return DictionaryModule.groupTypes
  }

  private mounted () {
    this.initialSelect()
    if (this.excludeCourseId) {
      this.filter.excludeCourseId = this.excludeCourseId
    }
    if (this.prolongation) {
      this.filter.packageType = PackageType.PROLONGATION
    }
  }

  private initialSelect() {
    this.innerSelected = this.selected
  }

  private handleFilter (field: ISearchPackagesFilterType, value: never) {
    this.filter = {
      ...this.filter,
      [field]: value,
      page: 1,
    }
  }

  @Bind
  @Debounce(300)
  private searchPackages () {
    ManagerCoursesModule.searchPackages(this.filter)
      .then((response: PackageShortResourcePaginator) => {
        this.packages = Object.assign({}, response)
      })
      .catch(this.notifyError)
  }

  private handleSelect () {
    this.$emit('select', this.innerSelected)
    this.innerSelected = []
    this.handleClose()
  }

  private handleClose () {
    this.$emit('update:visible', false)
  }

  @Watch('filter', { deep: true })
  private watchFilter () {
    this.searchPackages()
  }

  @Watch('visible')
  private watchVisible(value: boolean) {
    if (value)
      this.initialSelect()
  }
}
