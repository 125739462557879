










import { Component, Prop, Mixins } from 'vue-property-decorator'

import GroupPackagesForm from '@/components/forms/course/GroupPackagesForm.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'
import ManagerCoursesModule from '@/store/modules/manager/courses'
import { CourseLargeResource, GroupLargeResource, GroupType } from '@/store/types'

@Component({
  components: {
    GroupPackagesForm,
  },
})
export default class CoursesItemGroupPrices extends Mixins(NotifyMixin, PermissionsMixin) {
  @Prop({ required: true })
  private course!: CourseLargeResource

  @Prop({ required: true })
  private group!: GroupLargeResource

  private get packages () {
    return ManagerCoursesModule.packages
  }

  private mounted () {
    this.fetchPackages()
  }

  private destroyed () {
    ManagerCoursesModule.clearPackages()
  }

  private fetchPackages () {
    ManagerCoursesModule.fetchPackages({
      courseID: parseInt(this.$route.params.courseID, 10),
      type: this.$route.params.groupType as GroupType,
    })
      .catch((error: any) => {
        this.notifyError(error)
        this.$router.push({ name: 'manager.control.courses' })
      })
  }
}
