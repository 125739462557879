












































import { sumBy } from 'lodash'
import { Bind, Debounce } from 'lodash-decorators'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import ModalWrapper from '@/components/modals/ModalWrapper.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import SwitchInput from '@/components/_uikit/controls/SwitchInput.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import { GroupLargeResource, MonthShortResource, PackageStoreData } from '@/store/types'
import { formatDate } from '@/utils/functions'
import Dialog from '@/components/modals/Dialog.vue'

interface ProlongationForm {
  count: number,
  customCase: boolean,
  monthIds: number[],
}

@Component({
  components: {
    Dialog,
    ModalWrapper,
    Select,
    SwitchInput,
    TextInput,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class AddProlongationPackagesModal extends Mixins(NotifyMixin) {
  @Prop({ required: true })
  private visible!: boolean

  @Prop({ required: true })
  private group!: GroupLargeResource

  private form: ProlongationForm = {
    count: 1,
    customCase: false,
    monthIds: [],
  }
  private prolongations: PackageStoreData[] = []
  private invalidMonthsInput = false

  private get monthsList () {
    return this.group.months.map((month: MonthShortResource) => ({
      name: formatDate(month.name, 'LLLL'),
      value: month.id,
    }))
  }

  private mounted() {
    this.$bus.$on('resetPackagesAddForm', this.resetPackagesAddForm)
  }

  private generateMonths (): MonthShortResource[][] {
    const months: MonthShortResource[][] = []
    const count = +this.form.count

    this.group.months.forEach((month: MonthShortResource, index: number) => {
      if (index + count <= this.group.months.length) {
        months.push(this.group.months.slice(index, index + count))
      } else {
        return false
      }
    })

    return months
  }

  private addProlongation (months: MonthShortResource[]) {
    this.prolongations.push({
      monthIds: months.map((month: MonthShortResource) => month.id),
      price: sumBy(months, 'price'),
    })
  }

  // Проверка, что выбранные месяцы идут друг за другом подряд
  private validateMonthsOrder (months: MonthShortResource[]) {
    let prevMonthNum = +formatDate(months[0].name, 'M')
    let isOrderWrong = false

    for (let i = 1; i < months.length; i += 1) {
      const currentMonthNum = +formatDate(months[i].name, 'M')

      if (prevMonthNum + 1 === currentMonthNum || (prevMonthNum === 12 && currentMonthNum === 1)) {
        prevMonthNum = currentMonthNum
      } else {
        isOrderWrong = true
        break
      }
    }

    return isOrderWrong
  }

  @Debounce(300)
  @Bind
  private handleSubmit () {
    const form: any = this.$refs.form
    this.prolongations = []

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          if (this.form.customCase) {
            const addedMonths = this.group.months.filter((month: MonthShortResource) => this.form.monthIds.includes(month.id))
            if (!this.validateMonthsOrder(addedMonths)) {
              this.addProlongation(addedMonths)
              this.$emit('add', this.prolongations)
              // Закрытие модалки происходит в родительском компоненте. В случае успеха добавления пакета
              // эмитится событие на очищение формы, пакетов и закрытие модалки
            } else {
              this.notifyError('Выберите месяца без пропусков')
              this.invalidMonthsInput = true
            }
          } else {
            this.generateMonths().forEach((item: MonthShortResource[]) => this.addProlongation(item))
            this.$emit('add', this.prolongations)
            // Закрытие модалки происходит в родительском компоненте. В случае успеха добавления пакета
            // эмитится событие на очищение формы, пакетов и закрытие модалки
          }
        } else {
          this.notifyError('Проверьте введенные данные')
        }
      })
  }

  private handleClose () {
    this.$emit('close')
  }

  private resetPackagesAddForm() {
    this.$emit('close')
    this.form = {
      count: 1,
      customCase: false,
      monthIds: [],
    }
    this.prolongations = []
  }
}
